@import "common"
@import "vars"


#contact.-right .img
  left: $statue-margin
  right: calc(10% - #{$statue-margin})

.contact-page
  display: grid
  grid-template-columns: 1fr .9fr
  padding: 0 $mobile-wrapper-padding
  
  @media (max-width: $md)
    display: block

  &__submit 
    margin-bottom: 1.5rem
    
    @media (max-width: $md)
      width: 100%
      margin-bottom: 1rem

  > .img
    position: absolute
    background-size: contain
    top: 30%
    bottom: -10%
    left: 80px
    transition: left $slide-transition-duration ease-in-out, right $slide-transition-duration ease-in-out

    img
      height: 100%
      width: 100%
      object-position: left bottom
      object-fit: contain

  > .title
    z-index: 1
    @include page-header

  > .contact-form
    z-index: 1
    padding-right: 16px
    overflow-y: auto
    overflow-x: hidden

    @media  (max-width: $md)
      padding: 0 .5rem

  .contacts
    display: flex
    justify-content: space-between
    align-items: flex-start

    @media (max-width: $md)
      flex-direction: column
      margin-left: 0
      justify-content: space-between
      row-gap: 1rem

    a
      color: white
      text-decoration: none
      opacity: .7
      font-size: 1rem

      @media (max-width: $md)
        font-size: 1.2rem

      &:hover
        text-decoration: underline

    &__section
      display: flex
      flex-direction: column
      row-gap: 5px

      @media (max-width: $md)
        width: 100%
        flex-direction: row
        justify-content: space-between

.check-field
  display: block

  > .input
    display: none

  > .label
    display: block
    color: white
    font-size: 1.25rem
    font-family: 'Futura PT Book', sans-serif
    padding: 1rem
    border: 2px solid transparentize(white, .5)
    cursor: pointer
    outline: none
    user-select: none

    &:hover
      border-color: white

  > .input:checked + .label
    color: black
    background: white

.transparent-input
  font-family: 'Futura PT', sans-serif
  display: block
  background: transparentize(white, .92)
  color: white
  font-size: 1rem
  border: none
  border-bottom: 2px solid transparent
  backdrop-filter: blur(15px)
  padding: 1rem
  outline: none
  box-shadow: none

  &::placeholder
    color: transparentize(white, .5)

  &:focus::placeholder
    color: white

  &:invalid:not(.-virgin)
    background: rgba(255, 130, 130, 0.2)
    border-bottom: 2px solid #FF3E1D

  &:invalid:not(.-virgin) + .error-message
    display: block


  @supports not (backdrop-filter: blur(15px))
    background: mix(white, transparentize(black, .2), 10%)

    &:invalid:not(.-virgin)
      background: rgba(74, 4, 4, 0.9)

textarea.transparent-input
  resize: none


.contact-form
  flex-direction: column
  display: flex

  $labels-margin: 1.5rem

  > .caption
    margin-bottom: 1.5rem
    font-size: 1.75rem
    opacity: .75

  > .error-message
    display: none
    color: #FF3E1D
    margin-left: 1rem
    font-size: 1rem

    margin-top: -$labels-margin
    margin-bottom: $labels-margin

  > .checkboxes
    display: flex
    flex-wrap: wrap
    margin-bottom: $labels-margin

    > *
      margin-right: 1rem
      margin-bottom: 1rem

  > .transparent-input
    margin-bottom: $labels-margin
    align-self: normal

  > textarea
    min-height: 5rem

  > .animated-button
    align-self: flex-start

.contact__img
  @media (max-width: $md)
    display: none

.fasie-icon 
  width: 80%
  opacity: .7

  @media (max-width: $md)
    display: none

  &_mobile
    display: none

    @media (max-width: $md)
      display: block

.legal-person-info
  display: flex
  flex-direction: column
  row-gap: .5rem

.section-spacing
  margin-left: .5rem

